<template>
    <v-card :height="height">
        <l-map ref="map" :zoom="zoom" :center="center" style="z-index: 1 !important;">
            <v-tilelayer-googlemutant lang="es-CL" :options="{ type: 'hybrid' }" />
            <!--v-tilelayer-googlemutant apikey="YOUR_API_KEY" lang="es-CL" :options="{ type: 'hybrid' }" /-->
        </l-map>
    </v-card>
</template>

<script>
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import LDraw from 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';

import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';

export default {
    props: ['value', 'height'],
    components:{
        LMap,
        LMarker,
        LTileLayer,
        'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
    },
    data: () => ({
        zoom:18,
        center: L.latLng(-31.8226285,-70.5819457),
        layers: null
    }),
    computed: {
        result() {
            return this.layers._layers;
        },
    },
    methods: {
        clear() {
            this.layers.clearLayers();
        },
        set_position(latLng, zoom) {
            this.center = L.latLng(latLng[0], latLng[1]);

            if(zoom) {
                this.zoom = zoom;
            }
        },
        on_click(item) {
            console.log(item);
            this.$emit('marker-click', item);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.layers = new window.L.FeatureGroup();

            const map = this.$refs.map.mapObject;
            map.addControl(new window.L.Control.Fullscreen());

            const drawControl = new window.L.Control.Draw({
                position: 'topleft',
                draw: {
                    polyline: false,
                    polygon: false,
                    rectangle: false,
                    circle: false,
                    circlemarker: false,
                    marker: true
                },
                edit: {
                    featureGroup: this.layers,
                }
            });
            map.addLayer(this.layers);

            map.on(window.L.Draw.Event.CREATED, (e) => {
                // const type = e.layerType;
                const layer = e.layer;

                // Do whatever else you need to. (save to db, add to map etc)
                this.layers.addLayer(layer);
            });
        });
    },
    watch: {
        value: function(_new, _old) {
            if(!_new) return;

            console.log(_new);

            this.clear();
      
            var group = L.markerClusterGroup({
                maxClusterRadius: 40
            });
            
            _new.forEach((st, i) => {
                var marker = L.marker([st.lat, st.lon]);

                var htmlPopup = `<b>${st.name}</b><br/>`;
                htmlPopup += `Operador: ${st.operator}<br/>`;
                
                marker.on('click', (evt) => this.on_click(st));
                marker.bindPopup(htmlPopup);
                marker.addTo(group);
            })
            
            this.layers.addLayer(group);
        }
    }
}
</script>