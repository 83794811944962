<template>
    <div>
        <v-text-field v-if="searchable" v-model="busqueda" solo outlined hide-details flat dense :label="searchPlaceholder" prepend-inner-icon="search" />
        <v-card outlined class="mt-2 mb-2 overflow-auto" :min-height="300" :max-height="500">
            <v-data-iterator :items="items" :search="busqueda" hide-default-footer :items-per-page="-1">
                <template v-slot:item="{ item }">
                    <v-list-item @click="toggle_item(item)">
                        <v-list-item-action>
                            <v-checkbox v-model="value.filter(x => x.id == item.id).length > 0" color="primary" />
                        </v-list-item-action>
                        <v-list-item-content @click="">                           
                            <slot name="item-content" v-bind:item="item"></slot>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-data-iterator>
            <v-overlay absolute color="white" v-if="!items">
                <v-progress-circular indeterminate color="primary" />
            </v-overlay>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        items: {
            type: Array,
            default: null
        },
        searchable: {
            type: Boolean,
            default: false
        },
        searchPlaceholder: {
            type: String,
            default: "Buscar..."
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        busqueda: null
    }),
    methods: {
        toggle_item(item, trigger_evt = true) 
        {
            if(this.value.includes(item)) {
                this.value.splice(this.value.indexOf(item), 1);
                if(trigger_evt) {
                    this.$emit("unselect-item", item);
                }
            } else {
                this.value.push(item);
                if(trigger_evt) {
                    this.$emit("select-item", item);
                }
            }
        },
        clear() {
            this.value = []
            this.$emit("clear");
        }
    }
}
</script>