<template>
    <div>
        <v-row dense>
            <v-col cols="12">
                <VueApexCharts 
                    responsive
                    ref="grafico" 
                    width="100%" 
                    :type="type" 
                    :height="height" 
                    :options="options" 
                    :series="series">
                </VueApexCharts>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn class="ma-1" large depressed color="secondary" @click="move_left">
                    <v-icon large>chevron_left</v-icon>
                </v-btn>
                <v-btn class="ma-1" large depressed color="secondary" @click="move_right">
                    <v-icon large>chevron_right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn class="ma-1" large depressed color="secondary" @click="zoom_in">
                    <v-icon large>zoom_in</v-icon>
                </v-btn>
                <v-btn class="ma-1" large depressed color="secondary" @click="set_default">
                    <v-icon large>replay</v-icon>
                </v-btn>
                <v-btn class="ma-1" large depressed color="secondary" @click="zoom_out">
                    <v-icon large>zoom_out</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
    components: {
        VueApexCharts
    },
    props: {
        height: {
            type: Number,
            default: 350
        },
        type: {
            type: String,
            default: "line"
        },
        yrange: {
            type: Array,
            default: null
        },
        xaxis: {
            type: Object,
            default: () => ({
                type: 'numeric'
            })
        },
        yaxis: {
            type: Object,
            default: () => ({
                labels: { formatter: (val) => val }
            })
        },
        tooltip: {
            type: Object,
            default: () => ({
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            })
        },
        series: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        movement: 0,
        toggle_zoom: 4,
        options: {
            chart: {
                animations: { enabled: false },
                stacked: false,
                height: 350,
                toolbar: { show: false },
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                }
            },
            annotations: {
                yaxis: [],
                xaxis: []
            },
            xaxis: {
                type: 'numeric'
            },
            yaxis: {
                labels: { formatter: (val) => val },
                min: 0,
                max: 100
            },
            legend: {
                show: true,
                fontSize: 15,
                markers: {
                    width: 16,
                    height: 16,
                    radius: 2
                },
                itemMargin: { horizontal: 5 },
                itemOnClick: { toggleDataSeries: false }
            },
            stroke: { width: 2 },
            dataLabels: { enabled: false },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            responsive: [{
                breakpoints: 500,
                options: {
                    yaxis: {
                        show: false
                    }
                }
            }]
        }
    }),
    methods: {
        update() {
            var _new = {... this.options };
            this.options = this.update_apex(_new);
        },
        update_apex(_new) {
            _new.chart.height = this.height;
            _new.xaxis = this.xaxis;
            _new.yaxis = this.yaxis;
            _new.tooltip = this.tooltip;
            if(this.yrange) {
                _new.yaxis.min = this.yrange[0];
                _new.yaxis.max = this.yrange[1];
            } else {
                console.log("deleting yrange");
                delete _new.yaxis.min;
                delete _new.yaxis.max;
            }
            return _new;
        },
        add_y_line(y1, text, color, text_color) {
            var annotation = {
                y: y1,
                borderColor: color,
                label: {
                    borderColor: color,
                    style: {
                        color: text_color,
                        background: color
                    },
                    text: text
                }
            }

            var _new = { ... this.options };
            _new = this.update_apex(_new);
            this.options.annotations.yaxis.push(annotation);
            this.options = _new;
        },
        move(amount) {
            
            let showAll = this.toggle_zoom == 4;

            let minValue = this.series[0].data[2][0];
            let maxValue = this.series[0].data[2][0];
            let prop = [1, 'day'];

            if(this.toggle_zoom == -2) prop = [1, 'hour'];
            if(this.toggle_zoom == -1) prop = [12, 'hour'];
            if(this.toggle_zoom == 0) prop = [6, 'hour'];
            if(this.toggle_zoom == 1) prop = [1, 'day'];
            if(this.toggle_zoom == 2) prop = [2, 'day'];
            if(this.toggle_zoom == 3) prop = [3, 'day'];

            if(amount < 0) {
                minValue = moment(this.series[0].data[0][0]).subtract(Math.abs(amount) * prop[0], prop[1]).valueOf();
                maxValue = moment(this.series[0].data[0][0]).subtract(Math.abs(amount + 1) * prop[0], prop[1]).valueOf();
            } else {
                minValue = moment(this.series[0].data[0][0]).add(Math.abs(amount) * prop[0], prop[1]).valueOf();
                maxValue = moment(this.series[0].data[0][0]).add(Math.abs(amount + 1) * prop[0], prop[1]).valueOf();
            }

            let length = this.series[0].data.length - 1;

            if(this.toggle_zoom == 4) {
                minValue = this.series[0].data[0][0];
                maxValue = this.series[0].data[length][0];
            }

            console.log([minValue, maxValue]);

            this.$refs.grafico.updateOptions({
                xaxis: {
                    min: minValue,
                    max: maxValue
                }
            })
        },
        move_left() {
            this.movement -= 1;
            this.move(this.movement);
        },
        move_right() {
            this.movement += 1;
            this.move(this.movement);
        },
        zoom_out() {
            this.toggle_zoom += 1;
            this.toggle_zoom = Math.max(-2, Math.min(this.toggle_zoom, 4));
            this.move(this.movement);
        },
        zoom_in() {
            this.toggle_zoom -= 1;
            this.toggle_zoom = Math.max(-2, Math.min(this.toggle_zoom, 4));
            this.move(this.movement);
        },
        set_default() {
            this.toggle_zoom = 4;
            this.movement = 0;
            this.move(this.movement);
        }
    },
    watch: {
        yrange: function() {
            var _new = {... this.options };
            this.options = this.update_apex(_new);
        },
        xaxis: function() {
            var _new = {... this.options };
            this.options = this.update_apex(_new);
        },
        yaxis: function() {
            var _new = {... this.options };
            this.options = this.update_apex(_new);
        }
    }
}
</script>