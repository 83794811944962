<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('show_datos')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" md="4">
                <DashCard :actions="true" actions-align="right" body-class="ma-0 pa-0">    
                    <v-tabs fixed-tabs>
                        <v-tab><span class="subtitle-1 font-weight-bold">Estaciones</span></v-tab>
                        <v-tab><span class="subtitle-1 font-weight-bold">Variables</span></v-tab>
                        <v-tab-item class="pa-4">
                            <SelectableList 
                                searchable 
                                ref="estaciones_list" 
                                search-placeholder="Buscar estación..."
                                @select-item="show_map" 
                                @unselect-item="show_map" 
                                v-model="query.estaciones" 
                                :items="estaciones">
                                <template v-slot:item-content="{ item }">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.vtable.map(x => x.name) | string_array }}</v-list-item-subtitle>
                                </template>
                            </SelectableList>
                            <v-btn depressed color="primary" @click="$refs.estaciones_list.clear()">
                                <span class="subtitle-2">Vaciar</span>
                            </v-btn>
                        </v-tab-item>
                        <v-tab-item class="pa-4">
                            <SelectableList 
                                ref="variables_list" 
                                v-model="query.variables" 
                                :items="variables">
                                <template v-slot:item-content="{ item }">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </template>
                            </SelectableList>
                            <v-btn depressed color="primary" @click="$refs.variables_list.clear()">
                                <span class="subtitle-2">Vaciar</span>
                            </v-btn>
                        </v-tab-item>
                    </v-tabs>
                </DashCard>
            </v-col>
            <v-col cols="12" md="8">
                <StationsMapCard ref="mapa" v-model="estaciones" :height="640" @marker-click="select_station" />
            </v-col>
            <v-col cols="12" md="4">
                <DashCard title="Obtener valores" :actions="true" actions-align="right">
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-label>Inicio</v-label>
                            <v-datetime-picker :text-field-props="{ 'dense': true, 'solo': true, 'outlined': true, 'hide-details': true, 'prepend-inner-icon': 'event' }" v-model="query.inicio" ref="inicio" />
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-label>Termino</v-label>
                            <v-datetime-picker :text-field-props="{ 'dense': true, 'solo': true, 'outlined': true, 'hide-details': true, 'prepend-inner-icon': 'event' }" v-model="query.termino" ref="termino" />
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-label>Intervalo</v-label>
                            <v-select v-model="query.intervalo" hide-details solo flat outlined dense :items="intervals" />
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-label>Estadística</v-label>
                            <v-select v-model="query.estadistica" hide-details solo flat outlined dense :items="stats" />
                        </v-col>
                    </v-row>
                    <template v-slot:actions>
                        <v-btn depressed color="secondary" @click="generar">
                            <v-icon left>insert_chart_outlined</v-icon>
                            <span class="subtitle-2">Generar</span>
                        </v-btn>
                        <v-btn depressed color="secondary" @click="descargar">
                            <v-icon left>save_alt</v-icon>
                            <span class="subtitle-2">Descargar</span>
                        </v-btn>
                        <DownloadDialog ref="down_dialog" external-source />
                    </template>
                </DashCard>
            </v-col>
            <v-col cols="12" md="8">
                <DashCard title="Resultados" title-icon="timeline" body-class="pa-0">
                    <v-alert type="error" v-if="error">No se pudo generar datos con las propiedades seleccionadas</v-alert>
                    <v-alert type="warning" v-else-if="series.length < 1">No hay datos seleccionados que mostrar</v-alert>
                    <ZoomableXYChart ref="grafico" :height="350" :series="series" type="line" :xaxis="xaxis_conf" />
                    <LoadingOverlay v-model="loading" />
                </DashCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers';
import DashCard from '@/shared/components/DashCard'
import StationsMapCard from '@/dash/components/StationsMapCard'
import ZoomableXYChart from '@/dash/components/ZoomableXYChart'
import DatePickerText from '@/shared/components/DatePickerText'
import SelectableList from '@/dash/components/SelectableList'
import LoadingOverlay from '@/dash/components/LoadingOverlay'
import DownloadDialog from '@/shared/components/DownloadDialog'
import AccessMessage from '@/dash/components/AccessMessage'
import download from 'downloadjs'
import moment from 'moment'

export default {
    components: {
        DownloadDialog,
        LoadingOverlay,
        DashCard,
        StationsMapCard,
        ZoomableXYChart,
        DatePickerText,
        SelectableList,
        AccessMessage
    },
    data: () => ({
        xaxis_conf: {
            type: 'datetime',
            labels: {
                formatter: (val) => moment(val).add(4, "hour").format("L HH:mm")
            },
            tooltip: {
                formatter: (val) => moment(val).add(4, "hour").format("L HH:mm")
            }
        },
        tooltip_conf: {
            y: {
                formatter: (val) => val
            }
        },
        stats: [
            { text: "Promedio", value: "mean" },
            { text: "Mediana", value: "median" },
            { text: "Máxima", value: "max" },
            { text: "Mínima", value: "min" },
            { text: "Suma", value: "sum" },
            { text: "Acumulada", value: "accumulate" }
        ],
        intervals: [
            { text: "Original", value: null },
            { text: "Hora", value: "hour" },
            { text: "Día", value: "day" },
            { text: "Mes", value: "month" },
            { text: "Año", value: "year" }
        ],
        query: {
            estadistica: "mean",
            intervalo: null,
            inicio: moment().subtract(1, "day").set({ "hour": 0, "minute": 0 }).format("YYYY-MM-DD HH:mm").toString(),
            termino: moment().set({ "hour": 0, "minute": 0 }).format("YYYY-MM-DD HH:mm").toString(),
            variables: [],
            estaciones: []
        },
        index: {},
        results: null,
        error: false,
        error_data: null,
        loading: false
    }),
    computed: {
        estaciones() {
            var all = this.index.estaciones || [];
            if(this.query.variables.length < 1) {
                return all;
            }

            var codes = this.query.variables.map(x => x.code);

            return all.filter(x => x.vars.some(r => codes.includes(r)));
        },
        variables() {
            return this.index.variables || [];
        },
        series() {
            var data = this.results;

            if(!data) return [];

            var columns = data.columns.map(x => x.var_name + " - " + x.site_label);
            var series = data.series;
            
            var mix = [];
            series.forEach((x, i) => {
                mix.push({ name: columns[i], data: x });
            });
            
            return mix;
        },
    },
    methods: {
        show_map(item) {
            console.log("showing in the map");
            this.$refs.mapa.set_position([item.lat, item.lon], 17);
        },
        select_station(item) {
            this.$refs.estaciones_list.toggle_item(item, false);
        },
        get_query(descarga = false) {
            let query = { ...this.query };
            query.inicio = moment(query.inicio).format("YYYY-MM-DD HH:mm");
            query.termino = moment(query.termino).format("YYYY-MM-DD HH:mm");
            query.estaciones = query.estaciones.map(x => x.id);
            query.variables = query.variables.map(x => x.code);
            query.descarga = descarga;
            return query;
        },
        descargar() {
            let query = this.get_query(true);
            this.$refs.down_dialog.show('meteodata/visor/', query);
        },
        generar() {
            let query = this.get_query();
            this.loading = true;
            this.error = false;
            this.error_data = null;

            axiosHelper.post('meteodata/visor/', query)
                .then(response => {
                    this.results = response.data;
                    this.$refs.grafico.update();
                    this.loading = false;
                })
                .catch(response => {
                    this.error = true;
                    this.error_data = response;
                    this.loading = false;
                });
        }
    },
    mounted() {
        this.error = false;
        this.$emit('title', { text: "Datos por Estación", icon: "navigation" });

        if (!this.$parent.$parent.get_permission('show_datos')) return;

        axiosHelper.get('meteodata/visor')
            .then(response => {
                this.index = response.data;
            })
            .catch(response => {
                this.error = true;
                this.error_data = response;
            });
    }
}
</script>